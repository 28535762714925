// extracted by mini-css-extract-plugin
export var dotsWrapper = "style-module--dotsWrapper--9nSXb";
export var header = "style-module--header--jBq5+";
export var imageWrapper = "style-module--imageWrapper--bhiXg";
export var line = "style-module--line---vh-3";
export var option = "style-module--option--qF9B6";
export var root = "style-module--root--zAATB";
export var splash = "style-module--splash--U9wf4";
export var splashBottom = "style-module--splashBottom--jY5za";
export var text = "style-module--text--1n6Li";
export var title = "style-module--title--3CeBH";