export default {
  en: {
    title: "",
  },
  pl: {
    title: "Co na tym zyskasz?",
    options: [
      {
        image: require("./images/ico-planeta.svg").default,
        name: "Pomóż ratować planetę",
        text: "Od wieków ludzie bezmyślnie eksploatują ziemię, wykorzystują jej zasoby niewiele myśląc o konsekwencjach. Hack 4Change to niepowtarzalna okazja, by zrobić coś dla naszej wspaniałej planety i dla nas samych! Stań po stronie ziemi - bądź częścią rozwiązania, nie częścią problemu!",
      },
      {
        image: require("./images/ico-ludzie.svg").default,
        name: "Poznaj ludzi z pasją",
        text: "Poznaj takich jak Ty. Ludzi z pasją, którzy wierzą, że mają realny wpływ na otaczający ich świat i wykorzystują swój potencjał. Działaj wspólnie w jednym celu!",
      },
      {
        image: require("./images/ico-dzialanie.svg").default,
        name: "Przejdź do działania",
        text: "Masz pomysł na ciekawy projekt, ale brak Ci umiejętności by go zrealizować? A może przeciwnie, masz techniczną wiedzę, ale nie wiesz jak ją wykorzystać? Podczas hackathonu spotkasz wiele osób, takich jak Ty. Razem możecie więcej, połączcie siły i stwórzcie coś co działa!",
      },
      {
        image: require("./images/ico-eksperci.svg").default,
        name: "Ucz się od ekspertów",
        text: "Nasi mentorzy to eksperci w wielu dziedzinach. Ich wsparcie pomoże Ci udoskonalić twoje pomysły, a kiedy zbłądzisz naprowadzi na właściwy trop. A w późniejszym czasie, kto wie, może skorzystasz na takich znajomościach?",
      },
      {
        image: require("./images/ico-uczenie.svg").default,
        name: "Ucz się działając",
        text: "W tygodniu poprzedzającym Hackathon oraz w trakcie samego wydarzenia, będą odbywać się warsztaty o zróżnicowanej tematyce i poziomie zaawansowania, zarówno dla początkujących, jak i bardziej doświadczonych osób. Dzięki temu przygotujesz się do Hack4Change i od pierwszych godzin zaczniesz działać na rzecz planety!",
      },
      {
        image: require("./images/ico-rozglos.svg").default,
        name: "Zdobądź uznanie i rozgłos",
        text: "Najlepsze aplikacje mają szanse „wyruszyć w ekspedycję odporność” wraz z Markiem Kamińskim i wspierać dobrostan człowieka. O efektach hackatonu zdobywca biegunów będzie także opowiadał podczas licznych wystąpień i wywiadów na całym świecie.",
      },
    ],
  },
}
