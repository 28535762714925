import React from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import Text from "../Text"
import { Col, Row, Container } from "reactstrap"

import * as s from "./style.module.css"

function PartnersCoop({ lang }) {
  const t = useLabels(labels, lang)

  return (
    <div id="idea" className={s.root}>
      <div className={s.bgWrapper}>
        <div />
        <div />
      </div>
      <div className={s.splashWrapper}>
        <img src={t.splash} />
      </div>
      <Container className="h-100">
        <Row>
          <Col lg="6" className="position-relative">
            <div className={s.contentWrapper}>
              <img className={s.mobileImage} src={t.image} />
              <Text title={t.title} text={t.description} />
            </div>
          </Col>
          <Col lg="1" />
          <Col lg="5"></Col>
        </Row>
      </Container>
    </div>
  )
}

export default PartnersCoop
