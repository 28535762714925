import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Dots from "../Dots"
import Gadget from "../Gadget"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import * as s from "./style.module.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"


export default function Mentors({ lang }) {
  const t = useLabels(labels, lang)

  return (
    <div id="korzysci" className={s.root}>
      <img className={s.splash} src={require("./images/splash.svg").default} />
      <Container>
        <div className={s.header}>
          <h2 className="text-white">{t.title}</h2>
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />
        </div>
        <div className="position-relative">
          <Row xs={1} md={2} lg={3}>
            {t.options.map(option => (
              <Col className="mb-4">
                <Option {...option} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <img
        className={s.splashBottom}
        src={require("./images/splashBottom.svg").default}
      />
    </div>
  )
}

function Option({ image, text, name }) {
  return (
    <div className={s.option}>
      <div className="position-relative  d-inline-block">
        <img alt="" src={image} />
        <div className={s.dotsWrapper}>
          <Dots x={4} y={3} />
        </div>
      </div>
      <h4 className={s.title}>{name}</h4>
      <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}
