export default {
  en: {
    title: "",
  },
  pl: {
    title: "Propozycje zadań",
    options: [
      {
        image: require("./images/ico-planeta.svg").default,
        name: "Power4Resilience",
        text: "Zaproponuj rozwiązanie wspierające dobrostan człowieka i budowanie odporności psychicznej. Pomóż zadbać ludziom o swoje zdrowie psychiczne i fizyczne. Prototypy  rozwiązań mogą dotyczyć wyrabiania dobrych nawyków w obszarach pracy z ciałem, umysłem i relacjami. Pamiętaj o zasadzie równowagi i minimalizowaniu potrzeby obciążania środowiska! A teraz sam/a zrób pierwszy krok i wykonaj test Odporności Psychicznej 360, aby sprawdzić jaka jest Twoja odporność. Diagnoza punktu wyjścia jest warunkiem osiągania sukcesów.",
      },
      {
        image: require("./images/ico-ai.svg").default,
        name: "AItoJestWażne",
        text: "Zaproponuj rozwiązanie wykorzystujące sztuczną inteligencję do podejmowania skomplikowanych decyzji. Może będzie to optymalizacja tras przejazdów, a może wybór najlepszych lokalizacji farm wiatrowych lub słonecznych?",
      },
      {
        image: require("./images/ico-iot.svg").default,
        name: "IoT(O)chodzi",
        text: "Stwórz rozwiązanie wpisujące się w kategorię IoT. Pomóż optymalizować działania człowieka związane np. ze zużyciem energii.",
      },
      {
        image: require("./images/ico-city.svg").default,
        name: "MojeMiasto",
        text: "Zaprojektuj rozwiązanie, które pomoże Twojemu miastu w efektywnym wykorzystaniu swoich zasobów, lepszym funkcjonowaniu gospodarki, ale i budowaniu zdrowych relacji sąsiedzkich. Wykorzystaj otwarte dane, integruj informacje, spójrz na to co nie działa, pomóż żyć zdrowo, bezpiecznie i z uśmiechem!",
      },
      {
        image: require("./images/ico-trash.svg").default,
        name: "Freedom",
        text: "Stwórz rozwiązanie, które pomoże w walce z zanieczyszczeniem środowiska. Może uda Ci się stworzyć prototyp skanera kodów kreskowych, który będzie podpowiadał gdzie wyrzucać odpady?",
      },
      {
        image: require("./images/ico-openmind.svg").default,
        name: "Open(mind)set",
        text: "Stwórz rozwiązanie wykorzystujące otwarte dane w celu podnoszenia świadomości ekologicznej, walki ze zmianą klimatu czy wspierania swojego miasta w tworzeniu bardziej efektywnych i zielonych rozwiązań.",
      },
      {
        image: require("./images/ico-air.svg").default,
        name: "CO2jestGrane",
        text: "Stwórz rozwiązanie pozwalające redukować zużycie CO2. Każdy zredukowany gram jest na wagę złota - niech grywalizacja będzie drogowskazem. Może stworzysz aplikację lub grę, w której punkty przyznawane będą za każdy zaoszczędzony gram CO2?",
      },
      {
        image: require("./images/ico-cloud.svg").default,
        name: "Wolontariat",
        text: `Jednym z głównych obszarów realizacji wytycznych społecznej odpowiedzialności biznesu jest wolontariat pracowniczy. Jego uruchomienie w ramach działalności firmy przynosi wiele korzyści, szczególnie jeśli chodzi o wzmocnienie relacji pomiędzy pracownikami oraz ich integrację wokół wybranej idei i misji, jak również  wpływa na budowanie pozytywnego wizerunku firmy jako skutecznego narzędzia employer branding.<br/><br/>
Coraz więcej firm tworzy zatem programy wolontariatu pracowniczego, również z uwagi na zgłaszanie takiej potrzeby przez samych pracowników. Form realizacji wolontariatu jest bardzo dużo, są one bardzo różne i zależą od wielu czynników:<br/><br/>
(więcej tu: <a target="_blank" href="https://mfiles.pl/pl/index.php/Wolontariat_pracowniczy">https://mfiles.pl/pl/index.php/Wolontariat_pracowniczy</a>).<br/><br>
Jednak co zrobić, gdy pracownicy są rozproszeni po całej Polsce, a nawet świecie? Owszem istnieją aplikacje i e-wolontariat, ale skutecznie integrować ich ze sobą wokół wspólnej misji? Jak zachęcać jeśli będą realizowali swoje zadania każdy u siebie? Jak dowiedzieć się, czy faktycznie dany program wolontariatu spełnia swoje zadania? 
<br/><br/>Zaproponuj rozwiązanie, które zintegruje rozproszonych pracowników wokół wybranej misji wolontariatu. Może mieć w sobie elementy grywalizacji. Pamiętaj o tym, że wolontariat przynosi najlepsze efekty, gdy pracownicy angażują się w niego również w czasie pracy oraz sami wybierają, w co się zaangażują. 
`,
      },
    ],
  },
}
