// extracted by mini-css-extract-plugin
export var dotsWrapper = "style-module--dotsWrapper--3nV9G";
export var dotsWrapper2 = "style-module--dotsWrapper2--kuy8m";
export var dotsWrapper3 = "style-module--dotsWrapper3--8vqFV";
export var fade = "style-module--fade--ECRnx";
export var globalTitle = "style-module--globalTitle--77t5s";
export var header = "style-module--header--dE0-N";
export var imageWrapper = "style-module--imageWrapper--Ih5se";
export var modal = "style-module--modal--td6mj";
export var modalClose = "style-module--modalClose--9B86Q";
export var modalTitle = "style-module--modalTitle--6f8LA";
export var option = "style-module--option--Gis33";
export var root = "style-module--root--6Fg2u";
export var showMore = "style-module--showMore--cOk51";
export var splash = "style-module--splash--oQMAP";
export var text = "style-module--text--lG-fC";
export var text2 = "style-module--text2--jgzph";
export var textWrapper = "style-module--textWrapper--O4zDs";
export var title = "style-module--title--6GxSI";