import { useState, useEffect } from "react";
import { window } from "browser-monads"; //npm i browser-monads

function useWindowSize() {
  const [windowSize, setWindowSize] = useState();

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;