export default {
  en: {
    title: "",
  },
  pl: {
    title: "Poznaj opinie",
    opinions: [
      {
        name: "Michał Olech",
        title: "GUMED",
        text: "Hack4change to impreza rozwijająca ducha kreatywności i wieloaspektowego patrzenia na problemy. Każdy może wynieść coś wartościowego oraz nawiązać cenne kontakty. Warto w tym uczestniczyć w każdej możliwej roli."
      },
      {
        name: "Patryk Jar",
        title: "Kainos",
        text: "Hack4Change jest dobrym pomysłem na bardzo wielu poziomach. Dla młodych ludzi jest to świetny sposób na rozwój, poznanie bardzo wielu ciekawych ludzi i „złapanie” dobrych biznesowych kontaktów (jesteś juniorem, albo jeszcze studentem – lepiej nie trafisz). Niezależnie od tego, jak bardzo technologia zmieni oblicze pracy w przyszłości to umiejętność pracy w zespole i kreatywność zawsze będą kluczowe. Prócz tego jest to właściwe miejsce do zdobycia większej świadomości o skali wyzwań klimatycznych, ale i o technologicznych możliwościach przeciwdziałania im. A przecież nie jest wcale wykluczone, że pomysł, nad jakim uczestnicy zaczną pracować na hackathonie przerodzi się w prawdziwy produkt, który realnie wpłynie na życie wielu ludzi. Z pewnością dodatkowym atutem są też wszystkie dodatkowe atrakcje – joga, grill, team building i  wiele innych. 48h w gronie ciekawych ludzi robiących wspólnie ciekawe rzeczy. Do tego w Gdańsku. Czego więcej chcieć?"
      },
      {
        name: "Jan Cudzik",
        title: "COO in OutlineAI",
        text: "Hack4change to niezwykle potrzebna inicjatywa, której celem jest rozwiązywanie istotnych problemów społecznych poprzez innowacyjność i współpracę. Tegoroczny hackathon przekroczył moje oczekiwania - byłem pod wrażeniem organizacji i zaangażowania uczestników. Widziałem inspirujące projekty, które mają potencjał do realnej zmiany. Jestem pewien, że Hack4change jest niezwykle wartościowy i cieszę się, że mogłem wziąć w nim udział."
      },
      {
        name: "Pamela Krzypkowska",
        title: "Microsoft",
        text: "Hack4Change jest niesamowicie istotną inicjatywą dlatego, że potrzebujemy wszystkich sił które mamy żeby walczyć o naszą planetę i nas samych! Technologia jest niesamowitym narzędziem ale tak samo empatia, dobroć i bycie dla siebie ciepłym a to są też wartości, których w każdym Hack4Change jest co niemiara! Co roku to niesamowite miejsce gdzie można zająć się tym co ważne, ze wspaniałymi osobami i w super atmosferze."
      },
      {
        name: "Kamil Tomaszewski",
        title: "Uniwersytet SWPS",
        text: "Hack4Change to świetna i bardzo potrzebna inicjatywa. Nowe technologie słusznie mogą być wykorzystane w celu poprawy jakości życia człowieka i jego dobrostanu psychicznego. Widząc niesamowite zdolności uczestników, ich pomysły i zaangażowanie, jestem przekonany, że idea hackathonu jest bardzo owocna i wprost nie mogę się doczekać kolejnych edycji."
      },
      {
        name: "Marcin Młyński",
        title: "Co Founder & CEO @ CODE:ME",
        text: `Dawno tak nie drżał mi głos na scenie... uwielbiam to uczucie :)
            <br/><br/>
            Hack4Change Tech... to był jeden z najciekawszych projektów jakie udało mi się prowadzić, ale chyba też jeden z najtrudniejszych. 
            <br/><br/>
            Po tej jasnej stronie: świetne pomysły na ratowanie świata, pomysłowi uczestnicy, genialni mentorzy, wspaniali partnerzy, liczni przyjaciele wspierający to wydarzenie i cudowny zespół CODE:ME: Marta Dzięgielewska, Paulina Radwańska-Zubrzycka, Emilia Mikulska, Magdalena Rusak-Kodzis, Aneta Steichert, Jakub Jarosz... 
            <br/><br/>
            Dawno nie przyjąłem na swoje ręce tyłu podziękowań, i te podziękowania płynęły we wszystkie strony, od uczestników do mentorów, od mentorów do uczestników, między partnerami i organizatorami... pięknie było moc to obserwować od wewnątrz.
            <br/><br/>
            Dziękuję też za wspaniałe rozmowy, które udało się prowadzić, czy to na scenie w ramach panelu dyskusyjnego nt. zagrożeń współczesnego świata ze Stanislaw Szultka, Damian Andrzejewski, PhD, Pamela Krzypkowska, Mirosław Pałysiewicz, Sergiusz Ryczel, czy podczas spotkań mentoringowych z zespołami, czy wszystkich dywagacji i rozmyślań na korytarzach i zakamarkach fenomenalnych przestrzeni Dom zdrojowy Hevelianum oraz Słupski Inkubator Technologiczny | Slupsk Technology Incubator i wszystkich wieczornych, czy nocnych dyskusji.
            Myślę, że ich ton dobrze nadała także inspirująca prelekcja Marek Kaminski i energia przekazana przez Sergiusz Ryczel, który do samego końca wspierał i zarażał pasją nas wszystkich.
            <br/><br/>
            Oczywiście nie obyło się bez potknięć, bardzo trudnych chwil, zmęczenia, a czasem nawet zwątpienia. Niemniej myślę, że udało zorganizować bardzo mocne merytorycznie, ciekawe i inspirujące wydarzenie, a powstałe projekty rzeczywiście będą zmieniać świat, realizując słowa Mahatma Gandhi  - "Be The Change You Want To See In The World".
            <br/><br/>
            Jeszcze raz dziękuję głównym organizatorom: CODE:ME, Urząd Marszałkowski Województwa Pomorskiego, Marek Kaminski Academy
            i partnerom: Microsoft, Invest in Pomerania, EPAM Systems, Platinum Alfa - Fundusz Inwestycyjny, Nexio Management Ltd., Beeffective - Digital Marketing Agency, Hackerspace Trójmiasto
            partnerom honorowym: Ministerstwo Klimatu i Środowiska, Miasto Słupsk, Marszałek Województwa Pomorskiego 
            oraz wszystkim partnerom merytorycznym, społecznościowym oraz medialnym za nieocenioną pomoc. 
            <br/><br/>
            Dziękuję wszystkim trenerom, którzy poprowadzili 11 świetnych warsztatów, 30 ekspertom i jurorom, którzy dzielili się swoją wiedzą i wspierali uczestników (i nas:)), dziękuję firmom i osobom, które zaangażowały się w sprzątanie rzeki Redy.  Nie jestem w stanie wymienić Was tutaj wszystkich, ale jesteście nieocenieni. 
            <br/><br/>
            Szczególne, dodatkowe podziękowania dla Magdalena Drożdż, Magdalena Maszewska, Paulina Młyńska, Piotr Gaczkowski, Robert Symanowicz, Bogna Lesner za zaufanie, wiarę, wsparcie i cierpliwość ;) 
            <br/><br/>
            Na koniec jeszcze raz gratuluję zwycięzcom i wszystkim uczestnikom, który postanowili zmienić świat!`
      },
      {
        name: "Anna Nowosielska",
        title: "Director at PwC",
        text: `Z przyjemnością obserwowałam zmagania uczestników Hackthonu i nie zazdroszczę trudnej roli jury, by wybrać ten najlepszy. Wszystkie z nich wniosły niesamowitą dozę optymizmu i zarażały swą energia. Gdyby tylko była możliwość wprowadzenia ich wszystkich razem w życie, już dziś…..
            Tym bardziej gratulacje dla zwycięskiego projektu! 
            "if you want to go fast, go alone. If you want to go far, go together"
            Oni poszli po zwycięstwo razem!`
      },
      {
        name: "Paulina Radwańska-Zubrzycka",
        title: "Project Manager",
        text: `Po rozpoczęciu Hack4Change nadszedł czas na budowanie zespołów. Wraz z Emilia Mikulska wchodzimy do salki, żeby zerknąć okiem organizatora CODE:ME co się dzieje ;) 
            <br/><br/>
            A tam, pomiędzy kilkoma nie znającymi się wcześniej osobami nawiązuje się rozmowa - a Ty czym się zajmujesz?<br/> Jestem UX-designerem <br/> - Ja studiuję socjologię <br/>- A ja pisze apki webowe od kilkunastu lat <br/>- Ja jestem front-endowcem <br/>- Ja ogarniam social media... 
            <br/>
            <br/>Lepszego teamu nie można sobie było wymarzyć na Ekologiczno-technologiczny Hackathon! 💚 Z nic nie zapowiadającej rozmowy, powstał zwycięski zespół z mega kreatywnym pomysłem na biznes. 💪
            `
      },
      {
        name: "Maria Wójcik",
        title: "UX Designer",
        text: `Trudno jest dojść do siebie po takim weekendzie...
            <br/><br/>
            Gdy publikowałam tu niedawno link do artykułu, o tym jak amerykańska firma odzieżowa Patagonia zamierza zmienić swój model biznesowy, przez myśl mi nie przeszło, że mogłabym kiedyś dołożyć kamyczek do podobnej zmiany.
            <br/><br/>
            Na Hack4Change Tech jechałam z myślą o nowym projekcie do portfolio 😁 To co się wydarzyło jest dla mnie do tej pory nie do ogarnięcia - mój pomysł zainspirowany artykułem o Patagonii wygrał!!! 🏆🏆🏆
            <br/><br/>
            Zrobiliśmy to razem ze wspaniałym zespołem 🙌, który powstał w pierwszy hackathonowy wieczór w wyniku przypadkowego spotkania w eventowej kantynie.
            <br/><br/>
            Nasza wygrana to nie koniec tej historii, wręcz odwrotnie – dopiero początek! 🚀🚀🚀`
      },
      {
        name: "Sergiusz Ryczel",
        title: "Konsultant marki osobistej",
        text: `„Ej💥 Ja chyba jednak nie chcę być po prostu komentatorem sportowym. To co jest mi potrzebne, to bycie wśród kozaków💪, którzy mają marzenia; którzy realizują swoje marzenia💚; którzy robią wielkie rzeczy.”🗝
            <br/><br/>
            Chcąc nie chcąc od czasu do czasu opowiadam swoją historię. Nie inaczej było podczas #hackatonu Hack4Change Tech. Pomyślałem, że błyskawiczna/skrócona wersja mojego #powerspeech będzie moją kolejną jednorazową wizytówką, dzięki której lepiej się poznamy. 
            Zatem posłuchajcie proszę dlaczego warto👇<br/>
            🤜zdefiniować swoje „dlaczego”<br/>
            🤜spojrzeć na swoje osiągnięcia z dystansu i je docenić, <br/>
            🤜skupić się na emocjach, które możemy dzielić z innymi
            <br/><br/>
            Czy zgadzasz się z moją ideą❓ Znasz swoje „dlaczego”❓`
      },
      {
        name: "Paweł Nieczuja-Ostrowski",
        title: "Frontend developer w Autopay",
        text: `
            W niedzielę (12.06.2022) zakończyła się II edycja Hack4change, technologiczno-ekologicznym hackathou, poświęconego naszej planecie i rozwojowi człowieka. Uczestniczyło w nim ponad 80 osób z całej Polski, i aż 30 ekspertów z różnych dziecin.
<br/><br/>
W ramach hackatonu 12 zespołów przygotowało projekty aplikacji w takich obszarach wyzwań, jak Człowiek i jego dobrostan, Otwarte dane i Smart cities. Jury przyznało trzy nagrody i jedno wyróżnienie. Cieszy mnie szczególnie docenienie naszej pracy i przyznanie drugiego miejsca naszemu projektowi aplikacji wspierającej dzieci z wyzwaniami, zwłaszcza z autyzmem (Żeton), który realizujemy wspólnie z Leszek Miotk i Magdalena Rosłaniec (o projekcie na: <a href='https://lnkd.in/eHtEMSeZ'>https://lnkd.in/eHtEMSeZ</a>)
<br/><br/>
Niezapomniane przeżycie. Gratulacje i podziękowania dla organizatorów i uczestników za świetne merytorycznie wydarzenie i wspaniałą atmosferę. 
<br/><br/>
Organizatorami byli CODE:ME, Marek Kaminski Academy, Urząd Marszałkowski Województwa Pomorskiego oraz partnerujące firmy: Microsoft oraz EPAM Systems.
            `
      },
      {
        name: "Elnura Rysbekova",
        title: "IT Project Manager",
        text: `
        Thanks to Karolina Lipińska and CODE:ME, I had a great chance to meet amazing and talented people during 3 days of the Hackathon thus enlarging my network. Surely, can say that I was able to get a unique and valuable experience working with these great people.
        `
      }
    ]
  }
}