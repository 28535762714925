export default {
  en: {
    title: "",
  },
  pl: {
    title: "Obszary tematyczne",
    projectSectionTitle: "Nasze wyzwania: ",
    topics: [
      {
        id: "socialempowerment",
        image: require("./images/human.svg").default,
        imageMobile: require("./images/humanMobile.svg").default,
        description: `
          <strong>W ramach tej ścieżki będziemy poruszać w szczególności kwestie stanu psychicznego człowieka, jak wpłynęła na niego pandemia oraz brak stabilizacji na świecie.</strong>
          <br/><br/>
          Stawiamy pytania: Jak radzić sobie ze zmianą i stresem? Jak osiągać cele w trudnych warunkach? Jak wspomagać odporność psychiczną człowieka? Projekty uczestników będą skupiały się na szukaniu rozwiązań wpływających na poprawę naszego zdrowia fizycznego i psychicznego!`,
        button: "Zobacz więcej &rarr;",
        longDescription: "",
        topicsPageFullDesc:
          "Chcesz zmienić świat? Najpierw zatroszcz się o siebie. W myśl zasady: kamizelki ratunkowe najpierw zakładamy sobie, później pomagamy innym, naszą walkę o ochronę planety zaczynamy od <strong>człowieka.</strong> Budowanie lepszego świata musi mieć początek w budowaniu lepszego siebie, nie zapominajmy więc o potrzebach człowieka! W trudnych czasach pandemii i kryzysu, człowiek potrzebuje wsparcia bardziej niż kiedykolwiek. Pomóżmy więc sobie i innym mierzyć się z izolacją społeczną, lękiem i brakiem motywacji. Znajdźmy rozwiązania, które pomogą człowiekowi odnaleźć się w nowym, postpandemicznym świecie.",
      },
      {
        id: "sustainability",
        image: require("./images/nature.svg").default,
        imageMobile: require("./images/natureMobile.svg").default,
        description: `
          <strong>W tej ścieżce skupimy się na poszukiwaniu rozwiązań, których celem będzie ułatwienie nam codziennego życia.</strong>
          <br/><br/>
          Poszukujemy projektów, które wezmą pod uwagę rozwiązania ekologiczne i przyjazne dla środowiska. W obszarze tym mieścić się będą takie zagadnienia jak:
          <br/><br/>
          
            &#8226 monitorowanie zużycia i oszczędzanie energii<br/>
            &#8226 odnawialne źródła energii<br/>
            &#8226 idea zero waste, recykling<br/>
            &#8226 projekty niosące nacisk na właściwe wykorzystanie jednostek wytwórczych (optymalizacja ich pracy z wykorzystaniem najnowszych technologii)<br/>
            &#8226 oszczędzanie paliw dla przyszłych pokoleń i czyste powietrze w miastach.
          
        `,
        button: "Zobacz więcej &rarr;",
        longDescription:
          "<p><strong>CO2jestGrane <span style=color:#6F2FFA;>(Smart Cities)</span></strong> - stwórz rozwiązanie pozwalające zredukować zużycie CO2. Każdy zredukowany gram jest na wagę złota - niech grywalizacja będzie drogowskazem. Może stworzysz aplikację lub grę, w której punkty przyznawane będą za każdy zaoszczędzony gram CO2?</p><p><strong>IoT(O)chodzi <span style=color:#FBBD17;>(Człowiek i jego dobrostan)</span></strong> - stwórz rozwiązanie wpisujące się w kategorię IoT. Pomóż optymalizować działania człowieka związane np. ze zużyciem energii.</p><p><strong>AItoJestWażne (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #23c4fa'>Otwarte Dane</span>, <span style='color: #fbbd15'>Człowiek i jego dobrostan</span>)</strong> - zaproponuj rozwiązanie wykorzystujące sztuczną inteligencję do podejmowania skomplikowanych decyzji. Może będzie to optymalizacja tras przejazdów, a może wybór najlepszych lokalizacji farm wiatrowych lub słonecznych?</p>",
        topicsPageFullDesc:
          "Człowiek jest częścią <strong>natury</strong>, jej dobro idzie w parze z naszym dobrem. Mimo to ziemia nie ma z nami lekko. Od wieków niewiele myśląc o konsekwencjach, eksploatujemy swoją planetę i doprowadzamy ją do ruiny. Przyszedł czas aby zacząć myśleć o skutkach naszych działań i odpowiedzialnie  korzystać z dóbr natury. W końcu mamy tylko jedną planetę, tylko jedno środowisko, o które musimy dbać dla swojego własnego dobra. Pomóż nam odciążyć planetę ziemię. Stwórz rozwiązanie, które ograniczy eksploatację jej zasobów lub pozwoli rozważnie je wykorzystywać.",
      },
      {
        id: "singularity",
        image: require("./images/technology.svg").default,
        imageMobile: require("./images/technologyMobile.svg").default,
        description: `
          <strong>Zadaniem uczestników, będzie stworzenie projektu wspierającego proekologiczny rozwój technologiczny.</strong>
          <br/><br/>
          Projekty mogą dotyczyć pomysłów, na ponowne wykorzystanie już istniejących rozwiązań, jak i stworzeniu nowatorskich projektów, od zera. Stwórz rozwiązanie, które wykorzysta technologię w obszarach takich jak:
          <br/><br/>
          
            &#8226 gospodarka<br/>
            &#8226 środowisko<br/>
            &#8226 mobilność<br/>
            &#8226 zarządzanie i zrównoważony rozwój<br/>
            &#8226 smart cities, smart house (IoT)<br/>
            &#8226 AI
        `,
        longDescription:
          "<p><strong>Open(mind)set (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #23c4fa'>Otwarte Dane</span>)</strong> - stwórz rozwiązanie wykorzystujące otwarte dane w celu podnoszenia świadomości ekologicznej, walki ze zmianą klimatu czy wspierania swojego miasta w tworzeniu bardziej efektywnych i zielonych rozwiązań.</p><p><strong>Z głową w Chmurach (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #23c4fa'>Otwarte Dane</span>, <span style='color: #fbbd15'>Człowiek i jego dobrostan</span>)</strong> - zaprojektuj rozwiązanie wykorzystujące chmurę Azure. W pracy nad projektem wykorzystasz umiejętności nabyte podczas warsztatów przygotowawczych z Microsoft oraz dostęp do MS Azure który od nas otrzymasz.</p><p><strong>Freedom <span style='color: #6f2ffa'>(Smart Cities)</span></strong> - stwórz rozwiązanie, które pomoże w walce z zanieczyszczeniem środowiska. Może uda Ci się stworzyć prototyp skanera kodów kreskowych, który będzie podpowiadał gdzie wyrzucać odpady?</p>" +
          "<p><strong>MojeMiasto (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #23c4fa'>Otwarte Dane</span>)</strong> - zaprojektuj rozwiązanie, które pomoże Twojemu miastu w efektywnym wykorzystaniu swoich zasobów, lepszym funkcjonowaniu gospodarki, ale i budowaniu zdrowych relacji sąsiedzkich. Wykorzystaj otwarte dane, integruj informacje, spójrz na to co nie działa, pomóż żyć zdrowo, bezpiecznie i z uśmiechem!</p> <p><strong>Less is more (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #fbbd15'>Człowiek i jego dobrostan</span>)</strong> - stwórz rozwiązanie ułatwiające dokonywanie racjonalnych decyzji zakupowych w zgodzie z ideą less is more.</p> <p><strong>Emergencja (<span style='color: #6f2ffa'>Smart Cities</span>, <span style='color: #fbbd15'>Człowiek i jego dobrostan</span>)</strong> - zaproponuj rozwiązanie wspierające budowanie odporności psychicznej poprzez wyrabianie dobrych nawyków w obszarach pracy z ciałem, umysłem i relacjami. Rozwiązanie powinno wzmaniać wzajemne powiązania pomiędzy obszarami i mieć na uwadze zachowanie równowagi, minimalizowanie potrzeby obciążania środowiska ( np. kupna kolejnych butów, specjalnego sprzętu).</p>" +
          "<p><strong>Biofeedback (<span style=color:#FBBD17;>Człowiek i jego dobrostan</span>)</strong> - stwórz prototyp urządzenia lub aplikacji, która pomaga dbać o zdrowie psychiczne poprzez zbieranie i analizę wskaźników fizycznych organizmu (takich jak tętno, oddech czy ciśnienie) i ich wpływu na zasoby psychiczne (np. powiązaniei podniesionego tętna bez wysiłku fizycznego ze strachem lub stresem).</p>",
        topicsPageFullDesc:
          "Wielu ludzi uważa, że ekologia i technologia to przeciwności, naturalni wrogowie. Pomóż nam obalić to jakże mylne przekonanie! Pokażmy światu, że <strong>technologia</strong> może nie tylko nie szkodzić naturze, ale wręcz przyczyniać się do jej ratowania. Spraw by technologia służyła poprawie relacji między człowiekiem, a jego środowiskiem. Szukaj nowatorskich rozwiązań problemów naszej planety, zaproponuj nowe zastosowania dla istniejących rozwiązań technologicznych lub zaprojektuj zupełnie nowe. Niech technologia stanie się narzędziem, które usprawnia i optymalizuje symbiotyczną relację na linii człowiek-natura.",
      },
    ],
  },
}
