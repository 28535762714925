// extracted by mini-css-extract-plugin
export var active = "style-module--active--RtWLC";
export var dotsWrapper = "style-module--dotsWrapper--ZDtKi";
export var dotsWrapper2 = "style-module--dotsWrapper2--VwmWn";
export var faq = "style-module--faq--TwHo9";
export var header = "style-module--header--A20Vz";
export var icon = "style-module--icon--SCB4k";
export var line = "style-module--line--W0sUx";
export var pointer = "style-module--pointer--AKnyQ";
export var root = "style-module--root--ex2OA";
export var splashWrapper = "style-module--splashWrapper--zl1+C";