import React, {useState} from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Dots from "../Dots"
import Gadget from "../Gadget"
import CustomModal from "./modal"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import * as s from "./style.module.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function TaskProposal({ lang }) {
  const t = useLabels(labels, lang)
  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const isModalOpen = (data) => {
   setModal(data)
  }

  return (
    <div id="propozycje" className={s.root}>
      <Container>
        <div className={s.header}>
          <h2
            className={s.globalTitle}
            dangerouslySetInnerHTML={{ __html: t.title }}
          />
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />
        </div>
        <div className="position-relative">
          <Row xs={1} md={2} lg={3}>
            {t.options.map(option => (
              <Col className="mb-4">
                <Option optionData={option} setModalData={setModal}/>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      
      <img className={s.splash} src={require("./images/splash.svg").default} />
      {modal && <CustomModal modalData={modal} isModalOpen={isModalOpen} />}
    </div>
  )
}

function Option({ optionData, setModalData }) {

  const { image, text, name } = optionData

  let longerText;

  if(name === "Wolontariat") {
    longerText = 
    <div className={s.textWrapper}>
        <p className={s.text2} dangerouslySetInnerHTML={{ __html: text }} />
        <div className={s.fade}></div>
        
        <span className={s.showMore} onClick={() => setModalData(optionData)}>Pokaż więcej</span>
    </div>
  } else {
    longerText = <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
  }

  return (
    <div className={s.option}>
      <div className="position-relative d-inline-block">
        <LazyLoadImage effect="blur" alt="" src={image} />
      </div>
      <h4 className={s.title}>{name}</h4>
      {longerText}
    </div>
  )
}
