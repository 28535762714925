export default {
  en: {
    title: "",
  },
  pl: {
    title: "Społeczna odpowiedzialność",
    description: `
      <p>Hack4Change to inicjatywa, która nie tylko koncentruje się na innowacjach technologicznych, ale także działa na rzecz społeczeństwa i ochrony naszej planety:</p>
      <ul>
        <li>Promuje innowacyjne i ekologiczne rozwiązania</li>
        <li>Wspiera współpracę, której celem jest pozytywny wpływ na środowisko</li>
        <li>Angażuje lokalne społeczności</li>
        <li>Angażuje wolontariuszy</li>
        <li>Edukuje i podnosi świadomość na temat ochrony środowiska</li>
        <li>Napędza rozwiązania technologiczne na rzecz zrównoważonego rozwoju</li>
        <li>Zwiększa reputację i zaufanie marek Partnerów</li>
        <li>Wspiera pionierów w dziedzinie ekologii</li>
      </ul>
      `,
    image: require("./images/PartnersCoop.jpg").default,
    splash: require("./images/splash.svg").default,
  },
}
