import React, { useEffect, useState } from "react"
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import * as s from "./style.module.css"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import Slider from "react-slick";

export default function Carousel({ lang }) {

  const t = useLabels(labels, lang);
  const [currentCardData, setCurrentCardData] = useState({});

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (

    <div className={s.wrapper}>
      <Container>

        <div className={s.header}>
          <h2
            className={s.globalTitle}
            dangerouslySetInnerHTML={{ __html: t.title }}
          />
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />
        </div>

        <Slider {...settings}>
          {t.opinions.map(opinion => {
            return (<div className={s.opinion}>
              <h5 className={s.cardTitle}>{opinion.name}</h5>
              <span>{opinion.title}</span>
              <div className={s.divider} />
              <div className={s.cardTextWrapper}>
                <p className={s.cardText} dangerouslySetInnerHTML={{ __html: opinion.text }} />
              </div>
              <button className={s.cardButton} onClick={() => setCurrentCardData(opinion)} type="button" data-toggle="modal" data-target="#opinionModal">Zobacz więcej</button>
            </div>)
          })}
          <div className={s.opinion}>
            <div className="d-flex justify-content-center align-items-center">
              <img className={s.img} src={require("./images/1.png").default} />
            </div>
          </div>
          <div className={s.opinion}>
            <div className="d-flex justify-content-center align-items-center">
              <img className={s.img} src={require("./images/2.png").default} />
            </div>
          </div>
          <div className={s.opinion}>
            <div className="d-flex justify-content-center align-items-center">
              <img className={s.img} src={require("./images/3.png").default} />
            </div>
          </div>
        </Slider>

        {/* Modal */}

        <div class="modal fade" id="opinionModal" tabindex="-1" role="dialog" aria-labelledby="opinionModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content p-4">
              <div class="modal-header border-0">
                <div class="d-flex flex-column">
                  <h5 class="modal-title" id="opinionModalLabel">{currentCardData.name}</h5>
                  <span>{currentCardData.title}</span>
                  <div className={s.dividerLeft} />
                </div>
                <button type="button" class="close" onClick={() => console.log("exit")} data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p className={s.cardText} dangerouslySetInnerHTML={{ __html: currentCardData.text }} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={require("./images/rightArrow.svg").default} alt="Next arrow" />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img className={className} onClick={onClick} src={require("./images/leftArrow.svg").default} alt="Prev arrow" />
  );
}
