import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import * as s from "./style.module.css"
import { LazyLoadImage } from "react-lazy-load-image-component"

export default function CustomModal(modalData) {
  const [openModal, setOpenModal] = useState(true)
  const toggle = () => setOpenModal(!openModal)

  useEffect(() => {
    if (!modalData) return
    setOpenModal(true)
  }, [modalData])

  const {
    modalData: { image, text, name },
  } = modalData

  if (!openModal) return null

  return (
    <Modal className={s.modal} isOpen={openModal} toggle={toggle} onExit={() => {
      modalData.isModalOpen(false);
      setOpenModal(false);
    }
    }>
      <div className={s.modalClose} onClick={() => {
        modalData.isModalOpen(false);
        setOpenModal(false);
      }} />
      <ModalBody>
        <p
          className={s.modalTitle}
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </ModalBody>

    </Modal>
  )
}
