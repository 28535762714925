import React from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import Text from "../Text"
import Gadget from "../Gadget"
import Dots from "../Dots"
import { Col, Row, Container } from "reactstrap"

import * as s from "./style.module.css"
import { StaticImage } from "gatsby-plugin-image"

function BeTheChange({ lang }) {
  const t = useLabels(labels, lang)

  return (
    <div id="beTheChange" className={s.root}>
      <Container className="h-100">
        <Row className={s.rowWrapper}>
          <Col lg="6" className="position-relative">
            <div className={s.image2}>
              {/* <StaticImage alt="codeme" src="./images/beTheChange.png" quality="65" /> */}
              <StaticImage alt="codeme" src="./images/hackgroup.png" quality="75" />
              <StaticImage
              className={s.imageSplash}
              alt=""
              src="./images/beTheChangeSplash.svg"
            />
            </div>
          </Col>
          <Col lg="1" />
          <Col lg="5">
            <div className="mt-4">
              <Text title={t.title} text={t.description} />
            </div>
          </Col>
        </Row>
        <Row md="12" className="px-3">
          <div className={s.whoWeAreWrapper}>
            <Text title={t.whoWeAre.title} text={t.whoWeAre.description} />
          </div>
        </Row>
      </Container>
      <img
        className={s.bottomSplash}
        alt=""
        src={require("./images/bottomSplash.svg").default}
      />
    </div>
  )
}

export default BeTheChange
