export default {
  en: {
    title: "",
  },
  pl: {
    forWho: {
      title: "Dla kogo jest Hack4change?",
      description: `
      <p><strong>Do uczestnictwa w hackathonie zapraszamy innowatorów, programistów, grafików, UX designerów, socjologów, psychologów, ekologów i wszystkich tych, którym bliska jest idea walki z kryzysem klimatycznym i innymi problemami naszej planety.</strong></p>
      <p>W trakcie hackathonu uczestnicy połączą siły, tworząc interdyscyplinarne zespoły, które wspólnie będą pracowały nad prototypami proekologicznych urządzeń i aplikacji.</p>
      <p>Do dyspozycji uczestników będzie również grono mentorów - ekspertów ze świata technologii, nauki i biznesu, którzy będą dzielić się swoimi doświadczeniami z uczestnikami. Razem podejmiemy walkę z największymi zagrożeniami współczesnego świata, szukając prostych rozwiązań skomplikowanych problemów.</p>
      <p>Efektem hackathonu będą prototypy innowacyjnych rozwiązań wspierających dobrostan człowieka, walkę z zanieczyszczeniem środowiska, ociepleniem klimatu, poprawą komfortu życia w lokalnych środowiskach. Myślmy globalnie - zacznijmy lokalnie!</p>
      `,
      image1: "forWhoImg1.png",
      image2: "forWhoImg2.png",
      splash: require("./images/imgSplash.svg").default,
    },
    aroundH4c: {
      title: "Wokół Hack4change",
      description: `
      <p>Jak co roku, dla naszych uczestników przygotujemy kilka dni <strong>warsztatów</strong> z różnych technologii (m.in AI, chmura, data science, języki programowania, testowanie oprogramowania, prezentacje projektów).</p>
      <p>Celem warsztatów jest <strong>wprowadzenie uczestników w tematykę ścieżek tegorocznego hackathonu.</strong> Warsztaty mają za zadanie uczyć<br/> i inspirować. </p>
      <p>Do współpracy zapraszamy specjalistów i praktyków z branży IT oraz otoczenia biznesu.</p>
      `,
      image1: "aroundH4c1.png",
      image2: "aroundH4c2.png",
      splash: require("./images/aroundH4cSplash.svg").default,
    },
    river: {
      title: "Sprzątanie rzeki!",
      description: `
      <p>W ramach Hack4change, chcemy
      zaproponować Wam wspólny spływ kajakowy po rzece, wraz ze
      sprzątaniem terenu wokół spływu.
      W zeszłym roku spływ odbył się na rzece Reda.
      </p>
      `,
      image1: "kajaki.png",
      image2: "aroundH4c2.png",
      button: "Weź udział &rarr;"
    },
    forest: {
      title: "Sadzenie drzew!",
      description: `
      <p>W ramach praktycznych działań, mających rzeczywiste przełożenie na otaczający nas świat, w tym roku zajmiemy się sadzeniem drzew.<br/><br/>
      Chcemy, żeby nasze działania nie kończyły się wraz z finałem hackathonu. Dlatego akcję sadzenia drzew zaplanowaliśmy na jesień, kiedy warunki do nasadzeń będą najbardziej optymalne.
      </p>
      `,
      image1: "trees.png",
      image2: "aroundH4c2.png",
      button: "Weź udział &rarr;"
    },
    cooperation: {
      title: "Współpraca sponsorska",
      description: `
      <p><strong>Do szczególnej współpracy przy organizacji Hack4change, zapraszamy firmy i instytucje, które chciałby aktywnie włączyć się i współtworzyć wydarzenie.</strong></p>
      <a href='#form'>
        Dowiedz się więcej &rarr;
      </a>
      `,
      image1: "cooperation1.png",
      image2: "cooperation2.png",
      splash: require("./images/imgSplash.svg").default,
    },
  },
}
