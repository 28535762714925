import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import * as s from "./style.module.css"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import { Col, Row, Container, Button } from "reactstrap"
import Dots from "../Dots"
import useWindowSize from "../../hooks/useWindowSize"
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { LazyLoadImage } from "react-lazy-load-image-component"


export default function Topics({ lang }) {
  const windowSize = useWindowSize()
  const t = useLabels(labels, lang)

  return (
    <div id="obszary-tematyczne" className={s.bg}>
      <Container>
        <div className={s.header}>
          <h2>{t.title}</h2>
          <img src={require("../Text/icons/line.svg").default} />
        </div>
        <div className={s.root}>
          <Row xs={1} lg={3}>
            {t.topics.map(
              ({ value, description, image, imageMobile, button }) => (
                <Col>
                  <div className="d-flex align-items-center flex-column mb-4">
                    <div className="position-relative">
                      <LazyLoadImage
                        effect="blur"
                        alt=""
                        className={s.iconSize}
                        src={windowSize > 768 ? image : imageMobile}
                      />
                    </div>
                    <p
                      className={s.value}
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></p>
                  </div>
                </Col>
              )
            )}
          </Row>
        </div>
      </Container>
      <img className={s.splash} src={require("./images/splash.svg").default} />
    </div>
  )
}
