import React, { useState } from "react"
import { Container, Row, Col, Button, Collapse } from "reactstrap"
import Dots from "../Dots"
import Gadget from "../Gadget"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import * as s from "./style.module.css"

export default function Workshops({ lang }) {
  const t = useLabels(labels, lang)

  return (
    <div id="faq" className={s.root}>
      <Container>
        <div className={s.header}>
          <h2>{t.title}</h2>
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />
        </div>
        <div className="position-relative">
          <Row sm={1} md={2}>
            {t.faqs.map(faq => (
              <Col className="mb-4" xs={12}>
                <Faq {...faq} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <div className={s.splashWrapper}>
        <img src={require("./images/splash.svg").default} />
      </div>
    </div>
  )
}

function Faq({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className={`${s.faq} ${isOpen && s.active}`}>
      <div
        onClick={toggle}
        className={`d-flex align-items-center justify-content-between ${s.pointer}`}
      >
        <h5>{question}</h5>
        <div className={`${s.icon} ${isOpen && s.active}`} />
      </div>
      <Collapse isOpen={isOpen}>
        <div dangerouslySetInnerHTML={{ __html: answer }} className="mt-2" />
      </Collapse>
    </div>
  )
}
