// extracted by mini-css-extract-plugin
export var cardButton = "style-module--cardButton--OKcon";
export var cardText = "style-module--cardText--39-nW";
export var cardTextWrapper = "style-module--cardTextWrapper--kEqla";
export var cardTitle = "style-module--cardTitle--vo3Cw";
export var divider = "style-module--divider--owlSZ";
export var dividerLeft = "style-module--dividerLeft--4qUET";
export var globalTitle = "style-module--globalTitle--A3hcL";
export var header = "style-module--header--e85tT";
export var img = "style-module--img--pvPFp";
export var opinion = "style-module--opinion--70Clp";
export var root = "style-module--root--9yW2R";
export var wrapper = "style-module--wrapper--oC+Qa";