export default {
  en: {
    title: "",
  },
  pl: {
    title: "Be the change",
    description: `
      <p><strong>Podczas jednego weekendu uczestnicy Hack4change zmierzą się z wyzwaniami współczesnego świata!</strong></p>
      <p>Wydarzenie odbędzie się w formie stacjonarnej w Gdańsku.</p>
      <p>Wszyscy uczestnicy hackathonu, będą mieli równe szanse na dostarczenie najlepszego rozwiązania, które zmieni nasze najbliższe otoczenie i wpłynie na dobrostan człowieka.</p>
      <p><strong>Dlaczego hackathon?</strong></p>
      <p>Uważamy, że Hackathon, czyli maraton programowania, to forma eventu sprzyjająca wspólnemu poszukiwaniu rozwiązań dla bardzo rzeczywistych problemów!</p>
      <p>Wiemy, że podczas hackathonu wszystko jest możliwe!</p>
    `,
    whoWeAre: {
      title: "Kim jesteśmy?",
      description: `
      <div class="row">
        <p class="col-sm-12 col-md-6">Organizatorzy Hack4change to ludzie, którzy postanowili pasję do nowych technologii połączyć z proekologicznymi działaniami. Nasz hackathon nie tylko skupia się na technologii. Przede wszystkim stawiamy na nowatorskie pomysły w imię poprawy naszego otoczenia i życia. Myślmy globalnie, ale zacznijmy działania lokalnie!</p>
        <br/>
        <p class="col-sm-12 col-md-6">Fundacja CODE:ME oraz Kaminski Academy, już po raz trzeci chcą stworzyć dla wszystkich przestrzeń wspomagającą kreatywne myślenie! Chcemy Was zainspirować do myślenia w nurcie #bethechange! Bądźcie częścią tego wydarzenia!</p>
      </div>`,
    },
  },
}
