import React from "react"

import Layout from "../Layout"
import Hero from "../Hero"
import Idea from "../Idea"
import Numbers from "../Numbers"
import ForWho from "../ForWho"
import Topics from "../Topics"
import Marek from "../Marek"
import Timetable from "../Timetable"
import Mentors from "../Mentors"
import WhatEarn from "../WhatEarn"
import Faq from "../Faq"
import Gallery from "../Gallery"
import Partners from "../Partners"
import Contact from "../Contact"
import BeTheChange from "../BeTheChange"
import TaskProposal from "../TasksProposal"
import TimetableTabs from "../TimetableTabs"
import PartnersCoop from "../PartnersCoop"
import LazyHydrate from "react-lazy-hydration";
import loadable from '@loadable/component'
import Carousel from "../Carousel";



const Component = loadable(() =>
  import("../Mentors/index")
)
const Component2 = loadable(() =>
  import("../Topics/index")
)
const Component3 = loadable(() =>
  import("../PartnersCoop/index")
)
const Component4 = loadable(() =>
  import("../Partners/index")
)
const Component5 = loadable(() =>
  import("../TasksProposal/index")
)
const Component6 = loadable(() =>
  import("../WhatEarn/index")
)
const Component7 = loadable(() =>
  import("../Gallery/index")
)

const Component8 = loadable(() =>
  import("../Idea/index")
)




const IndexPage = ({ lang }) => (
  <Layout lang={lang}>
    <Hero lang={lang} type="heroHome" />
    <Numbers lang={lang} type="home" />
    <BeTheChange lang={lang} />
    <LazyHydrate whenVisible>

      <Component2 lang={lang} />
      <Component8 lang={lang} type="forWho" />
      <Carousel lang={lang} />
    </LazyHydrate>
    <LazyHydrate whenVisible>
    <Component5 lang={lang} />
    <Component lang={lang} />
    </LazyHydrate>
    <LazyHydrate whenVisible>
    <Component6 lang={lang} />
    {/*<TimetableTabs lang={lang} />*/}
    </LazyHydrate>

    <LazyHydrate whenVisible>
    <Component8 lang={lang} type="aroundH4c" isReverse />
    <Component8 lang={lang} type="river" />
    <Component8 lang={lang} type="forest" isReverse/>
    </LazyHydrate>
    <LazyHydrate whenVisible>
    <Faq lang={lang} />
    <Component7 lang={lang} />
    <Component4 lang={lang} />
    </LazyHydrate>
    <LazyHydrate whenVisible>
    <Component3 lang={lang} />
    <Component8 lang={lang} type="cooperation" />
    <Contact lang={lang} />
    </LazyHydrate>
  </Layout>
)

export default IndexPage
